<template>
    <div ref="a4" class="blank-page">
        <template v-if="selected_signs.length == 0" >
            <div v-for="index in pages" :key="index" class="wrapper" :class="{break:index > 0}">
                <div class="sign">
                    <SingleSaleCatalogy />
                </div>
                <div class="sign">
                    <SingleSaleCatalogy />
                </div>
                <div class="sign">
                    <SingleSaleCatalogy />
                </div>
            </div>
        </template>
        <template v-else>
            <div v-for="(page,index) in pagination.pages" :key="page" class="wrapper" :class="{break:index > 0}">
                <template v-for="sign in pagination.signs[index]" :key="sign.barcode">
                    <div class="sign">
                        <SignSale :sign="sign"/>
                    </div>
                </template>
            </div>
        </template>
        <div v-show="false" class="stam"></div>
    </div>

</template>

<script>
import { ref } from '@vue/reactivity'
import SignSale from './components/SignSale.vue'
import {is_sales_print,pagination_signs} from './Scripts/scripts'
import {is_deleted, printer_amount,selected_deals} from './components/Deals/Scripts/store'
import { watch } from '@vue/runtime-core'
import SingleSaleCatalogy from './components/ShilutCatalogy/SingleSaleCatalogy.vue'

export default {
    props:['pages','selected_signs'],
    components:{SignSale,SingleSaleCatalogy},
    setup(props){
       
        const {organise_signs,pagination} = pagination_signs()

        const a4 = ref()
        
        const handle_print = () => {
            var styleElem = document.createElement('style');
            styleElem.type = 'text/css' ;
            var css = `
                @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
                *{
                    direction:rtl;
                    font-family: 'Rubik', sans-serif;
                    font-weight:normal;
                }
                @media print {
                    .no-see-in-print{
                        display:none
                    }
                    .float-icon{
                        display:none;
                    }
                    .add-icon,.clean-icon{
                        display:none;
                    }
                    .break{
                        page-break-before: always;
                    }
                }
                .blank-page{
                    width:210mm;
                    min-height: 297mm;
                }
                .wrapper{
                    width:210mm;
                    height: 297mm;
                }
                .wrapper .sign{
                    width: 100%;
                    height: calc(100% / 3);
                    border:1px solid black;
                }
                .sign-sale{
                    width: 100%;
                    height: 100%;
                }
                .sign-sale-header{
                    position: relative;
                    width: 100%;
                    height: 10%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    border-bottom: 1px solid black;
                }
                .prormo-limit{
                    height: 10%;
                    width: 100%;
                    font-size: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .logo{
                    position: absolute;
                    width: 100px;
                    height: 100%;
                    top: 5px;
                    left: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .logo img{
                    width: 100px;
                    height: 50px;
                }
                 .sign-sale-input-texts{
                    position: relative;
                    width: 100%;
                    height: 30%;
                }
                .sign-sale-input-texts .he,.sign-sale-input-texts .ru{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 30px;
                    text-align: center;
                }
                .sign-sale-input-texts .ru{
                    font-size:18px;
                }
                .float-icon{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 5px;
                }
                .add-icon,.clean-icon{
                    color: var(--success);
                    cursor: pointer;
                    font-size: 35px;
                    user-select: none;
                }

                .clean-icon{
                    color: var(--danger);
                }

                .sign-sale-contents{
                    width: 100%;
                    height: 40%;
                    display: flex;
                    font-size: 50px;
                }
                .sign-sale-contents.catalog{
                    justify-content: center;
                    align-items: center;
                }
                .col{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .col-1{
                    width: 10%;
                    height: 100%;
                }
                .col-2{
                    width: 45%;
                    height: 100%;
                    
                }
                .col.barcodes{
                    justify-content:unset;
                    padding: 0 5px;
                    width: 40%;
                }
                .col.small{
                    font-size: unset; 
                    text-align: center;
                    width: 30%;
                    font-size: 25px;
                }
                .col.price{
                    width: 30%;
                    font-size: 25px;
                    flex-direction: column;
                }
                .col.price > small{
                    font-weight: 500;
                    font-size: 15px;
                }

                [data-slice]{
                    white-space: nowrap; 
                    overflow: hidden;
                    margin-right: 5px;
                }
                [data-fixed]{
                    display: flex;
                    align-items: center;
                }
                .sign-sale-footer{
                    width: 100%;
                    height: 10%;
                    display: flex;
                }
                 .sign-sale-footer.item-error{
                    justify-content: center;
                    align-items: center;
                    color: red;
                }
                .choos-item{
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: var(--main);
                    z-index: 1000;
                }

                .slide-in-top {
                -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                }

                @-webkit-keyframes slide-in-top {
                0% {
                    -webkit-transform: translateY(-1000px);
                            transform: translateY(-1000px);
                    opacity: 0;
                }
                100% {
                    -webkit-transform: translateY(0);
                            transform: translateY(0);
                    opacity: 1;
                }
                }
                @keyframes slide-in-top {
                0% {
                    -webkit-transform: translateY(-1000px);
                            transform: translateY(-1000px);
                    opacity: 0;
                }
                100% {
                    -webkit-transform: translateY(0);
                            transform: translateY(0);
                    opacity: 1;
                }
                }

            `
            if(styleElem.styleSheet){
                styleElem.styleSheet.cssText = css;
            }
            else{
                styleElem.appendChild(document.createTextNode(css));
            }
            
            let newWin= window.open('', '', 'height=500, width=500');
            newWin.document.write(a4.value.outerHTML);
            newWin.document.getElementsByTagName('head')[0].appendChild(styleElem);
            newWin.document.close();
            setTimeout(() => {
                is_sales_print.value = false
                newWin.print();
            }, 1000);
        }
        const handle_print_catalogy = () => {
            var styleElem = document.createElement('style');
            styleElem.type = 'text/css' ;
            var css = `
                @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
                *{
                    direction:rtl;
                    font-family: 'Rubik', sans-serif;
                    font-weight:normal;
                }
                @media print {
                    .no-see-in-print{
                        display:none
                    }
                    .float-icon{
                        display:none;
                    }
                    .add-icon,.clean-icon{
                        display:none;
                    }
                    .break{
                        page-break-before: always;
                    }
                }
                .blank-page{
                    width:210mm;
                    min-height: 297mm;
                }
                .wrapper{
                    width:210mm;
                    height: 297mm;
                }
                .wrapper .sign{
                    width: 100%;
                    height: calc(100% / 3);
                    border:1px solid black;
                }
                .sign-sale{
                    width: 100%;
                    height: 100%;
                }
                .sign-sale-header{
                    position: relative;
                    width: 100%;
                    height: 20%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    border-bottom: 1px solid black;
                }
                .prormo-limit{
                    height: 10%;
                    width: 100%;
                    font-size: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .logo{
                    position: absolute;
                    width: 100px;
                    height: 100%;
                    top: 5px;
                    left: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .logo img{
                    width: 100px;
                    height: 50px;
                }
                 .sign-sale-input-texts{
                    position: relative;
                    width: 100%;
                    height: 30%;
                }
                .sign-sale-input-texts .he,.sign-sale-input-texts .ru{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 30px;
                    text-align: center;
                }
                .sign-sale-input-texts .ru{
                    font-size:18px;
                }
                .sign-sale-contents{
                    width: 100%;
                    height: 40%;
                    display: flex;
                    font-size: 50px;
                }
                .sign-sale-contents.catalog{
                    justify-content: center;
                    align-items: center;
                }
                .col{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .col-1{
                    width: 10%;
                    height: 100%;
                }
                .col-2{
                    width: 45%;
                    height: 100%;
                }
                .col.barcodes{
                    justify-content:unset;
                    padding: 0 5px;
                    width: 40%;
                }
                .col.small{
                    font-size: unset; 
                    text-align: center;
                    width: 30%;
                    font-size: 25px;
                }
                .col.price{
                    width: 30%;
                    font-size: 25px;
                    flex-direction: column;
                }
                .col.price > small{
                    font-weight: 500;
                    font-size: 15px;
                }

                [data-slice]{
                    white-space: nowrap; 
                    overflow: hidden;
                    margin-right: 5px;
                }
                [data-fixed]{
                    display: flex;
                    align-items: center;
                }
                .sign-sale-footer{
                    width: 100%;
                    height: 10%;
                    display: flex;
                }
            `
            if(styleElem.styleSheet){
                styleElem.styleSheet.cssText = css;
            }
            else{
                styleElem.appendChild(document.createTextNode(css));
            }
            
            let newWin= window.open('', '', 'height=500, width=500');
            newWin.document.write(a4.value.outerHTML);
            newWin.document.getElementsByTagName('head')[0].appendChild(styleElem);
            newWin.document.close();
            setTimeout(() => {
                is_sales_print.value = false
                newWin.print();
            }, 1000);
        }

        if(props.selected_signs && props.selected_signs.length > 0){
            organise_signs(3,props.selected_signs)
        }

        printer_amount.value = []
        is_deleted.value = false
        watch(is_sales_print,()=>{
            if(is_sales_print.value && props.selected_signs.length > 0){
                setTimeout(() => {
                    handle_print()
                }, 500);
            }
            else if(is_sales_print.value && props.selected_signs.length == 0){
                 setTimeout(() => {
                    handle_print_catalogy()
                }, 500);
            }
        })
        
        watch(selected_deals.value,()=>{
            organise_signs(3,selected_deals.value)
        })
        
        return{
            handle_print,
            pagination,
            a4,
        }
    }
}
</script>

<style scoped>
    .blank-page{
        width: 100%;
        min-height: 100%;
        background: #fff;
        padding: 5px;
    }
    .wrapper{
        width: 100%;
        height: 297mm;
    }
    .wrapper .sign{
        width: 100%;
        height: calc(100% / 3);
        border:1px solid black;
    }


</style>